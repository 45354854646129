<template>
    <div class="date_widget_container">
        <img alt="Image" class="date_widget_calendar" src="../../assets/home/calendar.svg" />
        <div class="date_widget_date">
            {{ month }} <br />
            {{ day }}
        </div>
    </div>
</template>

<script>
import { format } from "date-fns";

export default {
    name: "Date",
    computed: {
        month() {
            return format(this.todaysDate, "MMM");
        },
        day() {
            return format(this.todaysDate, "dd");
        },
    },
    data() {
        return {
            todaysDate: new Date(),
        };
    },
};
</script>

<style lang="scss" scoped>
.date_widget_container {
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
}
.date_widget_calendar {
    height: 100%;
    width: 100%;
}
.date_widget_date {
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 14px;
}
</style>
