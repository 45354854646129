<template>
    <div v-if="!isLoading" class="quick_links_widget">
        <div class="quick_links_widget_title_holder">
            <div class="quick_links_widget_title">{{ $t("cp_home_quick_links_title") }}</div>
        </div>
        <div
            v-for="link in links"
            :key="link.value"
            class="quick_links_widget_body"
            @click="goTo(link.link)"
        >
            <font-awesome-icon class="quick_link_icon" :icon="['far', link.icon]" />
            <div class="body2 quick_link_name">
                {{ $t(link.value) }}
            </div>
            <font-awesome-icon class="quick_link_arrow_icon" :icon="['far', 'chevron-right']" />
        </div>
    </div>
    <div v-else class="loader">
        <LoaderCircular />
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faEnvelope,
    faInbox,
    faGlobe,
    faShapes,
    faChartBar,
} from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";

library.add(faChevronRight, faEnvelope, faInbox, faGlobe, faShapes, faChartBar);

export default {
    name: "QuickLinks",
    components: {
        FontAwesomeIcon,
        LoaderCircular,
    },
    props: {
        links: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        goTo(path) {
            this.$router.push({
                path,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.quick_links_widget {
    padding: 13px 24px 14px 24px;
    height: 100%;
}
.quick_links_widget_title_holder {
    padding-bottom: 13px;
}
.quick_links_widget_body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 14px 0 16px 0px;
    border-top: 1px solid $grey_alabaster;
    &:nth-of-type(2) {
        padding-top: 24px;
    }
    &:hover {
        cursor: pointer;
        color: $grey_manatee;
    }
}
.quick_link_icon {
    font-size: 12px;
    width: 12px;
    color: $blue;
}
.quick_link_name {
    width: 100%;
    padding-left: 13px;
}
.quick_link_arrow_icon {
    font-size: 10px;
    margin-right: 3px;
}

.quick_links_widget_title {
    @extend %subtitle3_style;
    font-weight: 700;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
