<template>
    <div class="container">
        <img
            v-if="placeholder === 'lady'"
            src="../../assets/home/placeholder-widgets/lady.svg"
            alt="Lady"
        />
        <img
            v-else-if="placeholder === 'stats'"
            src="../../assets/home/placeholder-widgets/stats.svg"
            alt="Stats"
        />
        <img
            v-else-if="placeholder === 'phone'"
            src="../../assets/home/placeholder-widgets/phone.svg"
            alt="Phone"
        />
        <img
            v-else-if="placeholder === 'desktop'"
            src="../../assets/home/placeholder-widgets/desktop.svg"
            alt="Desktop"
        />
    </div>
</template>

<script>
export default {
    name: "PlaceholderWidget",
    props: {
        placeholder: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
