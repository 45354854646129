<template>
    <div v-if="!isLoading" class="news_widget_container">
        <div class="news_widget_title_holder">
            <div class="news_widget_title">{{ $t("cp_home_news_title") }}</div>
        </div>
        <div class="news_widget_body">
            <div class="news_widget_body_item" v-for="item in news" :key="item.text">
                <div class="button_typography news_widget_body_date">
                    {{ formatDate(item.attributes.date) }}
                </div>
                <div
                    class="subtitle2 news_widget_body_text"
                    :class="item.attributes.type + '_border'"
                >
                    {{ $t(item.attributes.text) }}
                </div>
            </div>
        </div>
    </div>
    <div v-else class="loader">
        <LoaderCircular />
    </div>
</template>

<script>
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";

export default {
    name: "News",
    components: {
        LoaderCircular,
    },
    props: {
        news: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        formatDate(date) {
            const partsOFDate = date.split("-");
            return `${partsOFDate[2]}-${partsOFDate[1]}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.news_widget_container {
    padding: 12px 24px 12px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.news_widget_title_holder {
    padding-bottom: 13px;
    border-bottom: 1px solid $grey_alabaster;
}
.news_widget_body {
    padding: 12px 0 16px 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}
.news_widget_body_item {
    width: 100%;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    overflow-wrap: anywhere;
    &:last-of-type {
        padding-bottom: 0px;
    }
}
::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #f1f1f1;
    background-color: #fcfcfc;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c9c9c9;
}
.news_widget_body_date {
    width: 68px;
    padding: 3px 0 23px 0;
}
.news_widget_body_text {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    word-break: break-word;
    @media (min-width: 1201px) {
        padding-right: 20px;
    }
}
.tech_border {
    border-left: 2px solid $green;
}
.news_border {
    border-left: 2px solid $blue;
}
.marketing_border {
    border-left: 2px solid $blue;
}
.news_widget_title {
    @extend %subtitle3_style;
    font-weight: 700;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
