<template>
    <PlaceholderWidget v-if="placeholder" :placeholder="placeholder" />
    <div v-else class="wrapper">
        <button class="top_half" :class="{ top_half_link: link }" @click="onClick">
            <span class="title_wrapper" :class="{ title_wrapper_link: link }">
                <span class="title">{{ $t(title) }}</span>
                <font-awesome-icon
                    v-if="link"
                    size="sm"
                    class="chevron_icon"
                    :icon="['fas', 'chevron-right']"
                />
            </span>
        </button>
        <span class="account_name">{{ accountName }}</span>
        <div class="bottom_half">
            <div class="summary">
                <span class="value">{{ value }}</span>
                <span v-if="total" class="total">{{ `/ ${total}` }}</span>
            </div>
            <font-awesome-icon v-if="icon" class="icon" :icon="['fas', icon]" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faStar,
    faEnvelopeOpen,
    faCommentAltDots,
    faChevronRight,
    faClipboardList,
    faFileChartLine,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import PlaceholderWidget from "@/components/Home/PlaceholderWidget.vue";

library.add(
    faStar,
    faEnvelopeOpen,
    faCommentAltDots,
    faChevronRight,
    faClipboardList,
    faFileChartLine
);

export default {
    name: "NumericSummary",
    components: { FontAwesomeIcon, PlaceholderWidget },
    props: {
        title: {
            type: String,
        },
        value: {
            type: Number,
        },
        placeholder: {
            type: String,
        },
        total: {
            type: Number,
        },
        icon: {
            type: String,
            validate(value) {
                return (
                    [
                        "star",
                        "envelope-open",
                        "comment-alt-dots",
                        "clipboard-list",
                        "file-chart-line",
                    ].indexOf(value) === -1
                );
            },
        },
        link: {
            type: String,
            required: false,
        },
        openLinkInNewTab: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            accountName: "AccountStore/accountName",
        }),
    },
    methods: {
        onClick() {
            if (this.link) {
                if (this.openLinkInNewTab) {
                    window.open(this.link);
                } else {
                    this.$router.push(this.link);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.wrapper {
    width: 100%;
    height: 100%;
    padding: 12px 9px 14px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.top_half {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 12px;
    margin: 0;
    background: none;
    border: none;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    .title_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            @extend %subtitle3_style;
            font-weight: 700;
            margin-bottom: 2px;
        }
    }
    .title_wrapper_link {
        cursor: pointer;
    }
}
.top_half_link {
    &:hover {
        color: $grey_manatee;
        .title {
            color: $grey_manatee;
        }
    }
}
.account_name {
    @extend %caption_style;
    color: $grey_bombay;
    font-weight: 600;
    letter-spacing: 0;
    padding: 0 12px;
}
.bottom_half {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    user-select: none;
    margin-top: auto;

    .summary {
        line-height: 1;
        .value {
            @extend %headline4_style;
            margin: 0 4px 0 0;
            letter-spacing: 0;
        }
        .total {
            @extend %subtitle1_style;
            color: $grey_bombay;
            letter-spacing: 0;
        }
    }
    .icon {
        font-size: 24px;
        color: $blue;
    }
}
</style>
