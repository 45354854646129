<template>
    <div class="contact_link" @click="openContactPage">
        {{ $t("cp_home_feedback_or_question") }}
    </div>
</template>

<script>
export default {
    name: "Contact",
    methods: {
        openContactPage() {
            window.open(
                `https://www.feedbackcompany.com/contact${this.$t("cp_utm_parameters", {
                    module: "home",
                    term: "contact",
                })}`
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.contact_link {
    @extend %button_typography_style;
    width: 100%;
    height: 100%;
    padding: 29px 20px 29px 20px;
    text-align: center;
    font-weight: 700;
    border: 1px solid $grey_alabaster;
    border-radius: 8px;
    white-space: break-spaces;
    &:hover {
        background-color: $grey_alabaster;
        cursor: pointer;
        border: 1px solid $grey_athens;
    }
}
</style>
