<template>
    <div class="wrapper">
        <button class="top_half">
            <span class="title_wrapper">
                <span class="title">{{ $t(title) }}</span>
            </span>
        </button>
        <span class="account_name">{{ activeUser.platform }}</span>
        <div class="bottom_half">
            <div class="progress_bar">
                <vm-progress
                    :percentage="percentage"
                    :stroke-color="primaryBlue"
                    :track-color="lightBlue"
                    stroke-width="18"
                    type="line"
                    :show-text="false"
                >
                </vm-progress>
            </div>
            <div class="percentage">{{ percentage }}%</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ResponseRate",
    props: {
        title: {
            type: String,
            required: true,
        },
        percentage: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            primaryBlue: "#438bf8",
            lightBlue: "#e3eefe",
        };
    },
    computed: {
        ...mapGetters({
            activeUser: "AccountStore/activeUser",
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.wrapper {
    width: 100%;
    height: 100%;
    padding: 12px 9px 14px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.top_half {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 12px;
    margin: 0;
    background: none;
    border: none;

    .title_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            @extend %subtitle3_style;
            font-weight: 700;
            margin-bottom: 2px;
        }
    }
}
.account_name {
    @extend %caption_style;
    color: $grey_bombay;
    font-weight: 600;
    letter-spacing: 0;
    padding: 0 12px;
}
.bottom_half {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    user-select: none;
    margin-top: auto;

    .percentage {
        font-family: "Montserrat", sans-serif;
        font-size: 24px;
    }
}
.progress_bar {
    width: 150px;
}
</style>
