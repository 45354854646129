<template>
    <div v-if="!isLoading" class="home_banner">
        <div class="home_banner_info">
            <div class="home_banner_info_name" v-line-clamp:24="5">
                {{ $t("cp_home_banner_welcome") }},<br />
                {{ name }}
            </div>
            <div class="home_banner_info_subtitle">
                {{ bannerData.text }}
            </div>
            <Button
                v-if="bannerData.enableCTABtn"
                class="home_banner_info_learn_more"
                buttonStyle="secondary"
                @click.native="openPage()"
            >
                {{ $t("cp_home_banner_learn_more") }}
            </Button>
        </div>
        <div class="home_banner_image_div">
            <img class="home_banner_image" :src="bannerData.image" alt="welcome image" />
        </div>
    </div>
    <div v-else class="loader home_banner">
        <LoaderCircular />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import * as Sentry from "@sentry/vue";
import getBanner from "@/graphql/home/getBanner.gql";
import { format } from "date-fns";

export default {
    name: "Banner",
    components: {
        Button,
        LoaderCircular,
    },
    data() {
        return {
            bannerData: {},
            isLoading: true,
        };
    },
    computed: {
        ...mapGetters({
            activeUser: "AccountStore/activeUser",
            language: "LanguageStore/getLanguage",
        }),
        name() {
            return this.activeUser.firstName && this.activeUser.lastName
                ? this.activeUser.firstName
                : this.activeUser.username;
        },
    },
    watch: {
        language() {
            this.getBannerData();
        },
    },
    methods: {
        openPage() {
            window.open(this.bannerData.campaignUrl);
        },
        async getBannerData() {
            this.isLoading = true;
            try {
                const { data } = await this.$apollo.query({
                    query: getBanner,
                    client: "strapi",
                    variables: {
                        locale: this.language,
                        isAppreciation:
                            this.activeUser.isAppreciation && !this.activeUser.isEvaluation,
                        today: format(new Date(), "yyyy-MM-dd"),
                    },
                });
                this.bannerData = data.banners.data[data.banners.data.length - 1].attributes;
                this.isLoading = false;
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    },
    async created() {
        await this.getBannerData();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
@import "~include-media";

.home_banner {
    height: 100%;
    background: linear-gradient(-172.8749836510982deg, $blue -50%, $navy 100%);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: $white;
    @include media($isPhone...) {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
}
.home_banner_info {
    padding: 40px 0 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 455px;
    @media (max-width: 1200px) {
        padding: 25px 0 25px 17px;
        width: 262px;
    }
    @include media($isPhone...) {
        padding: 18px 0 18px 17px;
    }
}
.home_banner_info_name {
    @extend %headline4_style;
    margin-top: 0;
    @media (min-width: 1201px) {
        -webkit-line-clamp: 3 !important;
        > br {
            display: none;
        }
    }
    @media (max-width: 1200px) {
        font-size: 24px;
    }
    @include media($isPhone...) {
        font-weight: 600;
        font-size: 24px;
    }
}
.home_banner_info_subtitle {
    @extend %subtitle2_style;
    line-height: 20px;
    letter-spacing: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}
.home_banner_info_learn_more {
    height: 48px;
    width: 128px;
    margin-top: auto;
}
.home_banner_info_learn_more_nl {
    @media (max-width: 1200px) {
        margin-top: 10px;
    }
    @include media($isPhone...) {
        margin-top: auto;
    }
}
.home_banner_image_div {
    display: flex;
    padding-right: 40px;
    @media (max-width: 1200px) {
        padding: 0 24px 0 40px;
    }
    @include media($isPhone...) {
        padding: 20px 0 0 17px;
    }
}
.home_banner_image {
    width: 100%;
    height: auto;
    @include media($isPhone...) {
        width: 242px;
        height: 153px;
    }
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 290px;
}
</style>
