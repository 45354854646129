<template>
    <div class="home_page">
        <div class="widget_grid">
            <div class="banner"><Banner /></div>
            <div
                :class="'small_widget_' + index"
                v-for="(widget, index) in statistics"
                :key="index"
            >
                <div v-if="widget.isLoading" class="homepage__loader">
                    <LoaderCircular />
                </div>
                <div v-else class="statistics_widget">
                    <NumericSummary
                        v-if="widget.type === 'basic'"
                        :title="widget.title"
                        :value="widget.value"
                        :total="widget.total"
                        :link="getLink(widget)"
                        :icon="getIcon(widget.title)"
                        :placeholder="widget.placeholder"
                        :openLinkInNewTab="isCompanyScore(widget)"
                    />
                    <ResponseRate v-else :title="widget.title" :percentage="widget.value" />
                </div>
            </div>
            <div class="news_widget">
                <News :news="updates" :isLoading="isLoadingUpdates" />
            </div>
            <div class="quick_links_widget">
                <QuickLinks :links="quickLinks" :isLoading="isLoadingQuickLinks" />
            </div>
            <div class="date_widget"><Date /></div>
            <Contact class="contact_link_widget" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import * as Sentry from "@sentry/vue";
import ResponseRate from "@/components/Home/ResponseRate.vue";
import Contact from "@/components/Home/Contact.vue";
import NumericSummary from "@/components/Home/NumericSummary.vue";
import QuickLinks from "@/components/Home/QuickLinks.vue";
import News from "../components/Home/News.vue";
import Date from "../components/Home/Date.vue";
import Banner from "../components/Home/Banner.vue";
import getQuickLinks from "../graphql/home/getQuickLinks.gql";
import getUpdates from "../graphql/home/getUpdates.gql";
import getStatisticsWidget from "../graphql/home/getStatisticsWidget.gql";

const COMPANY_SCORE = "cp__homepage__widgets__company__score__widget__title";
const UNREAD_REVIEWS = "cp__homepage__widgets__unread__reviews__widget__title";
const COLLECTED_REVIEWS = "cp__homepage__widgets__collected__reviews__widget__title";
const COLLECTED_RESPONSES = "cp__homepage__widgets__collected__responses__widget__title";
const REPORTS = "cp__homepage__widgets__reports__widget__title";
const QUESTIONNAIRES = "cp__homepage__widgets__questionnaires__widget__title";

const EMPTY_WIDGET = {
    type: "basic",
    title: null,
    value: null,
    placeholder: null,
    link: null,
    isLoading: true,
};

export default {
    components: {
        Banner,
        NumericSummary,
        ResponseRate,
        Contact,
        QuickLinks,
        News,
        Date,
        LoaderCircular,
    },
    name: "Home",
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            language: "LanguageStore/getLanguage",
        }),
    },
    data() {
        return {
            statistics: [EMPTY_WIDGET, EMPTY_WIDGET, EMPTY_WIDGET],
            updates: [],
            quickLinks: [],
            isLoadingUpdates: false,
            isLoadingQuickLinks: false,
            placeholderWidgets: ["desktop", "phone", "stats", "lady"],
        };
    },
    watch: {
        language() {
            this.getUpdatesWidget();
        },
    },
    methods: {
        getIcon(widgetTitle) {
            if (!widgetTitle) return null;
            switch (widgetTitle) {
                case UNREAD_REVIEWS:
                    return "envelope-open";
                case COLLECTED_REVIEWS:
                case COLLECTED_RESPONSES:
                    return "comment-alt-dots";
                case REPORTS:
                    return "file-chart-line";
                case QUESTIONNAIRES:
                    return "clipboard-list";
                default:
                    return "star";
            }
        },
        getLink(widget) {
            if (this.isCompanyScore(widget)) {
                return `${this.activeAccount.companyPageUrl}${this.$t("cp_utm_parameters", {
                    module: "home",
                    term: "banner",
                })}`;
            }
            return widget.link;
        },
        isCompanyScore(widget) {
            return widget.title === COMPANY_SCORE;
        },
        async getQuickLinksWidget() {
            this.isLoadingQuickLinks = true;
            try {
                const { data } = await this.$apollo.query({
                    query: getQuickLinks,
                    variables: {
                        divisionUuid: this.activeAccount.externalUuid,
                    },
                });
                this.quickLinks = data.quickLinks;
                this.isLoadingQuickLinks = false;
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        async getUpdatesWidget() {
            this.isLoadingUpdates = true;
            try {
                const { data } = await this.$apollo.query({
                    query: getUpdates,
                    variables: {
                        locale: this.language,
                    },
                    client: "strapi",
                });
                this.updates = data.updates.data;
                this.isLoadingUpdates = false;
            } catch (error) {
                Sentry.captureException(error);
            }
        },
        async getStatisticsWidget(orderNumber) {
            try {
                const { data } = await this.$apollo.query({
                    query: getStatisticsWidget,
                    fetchPolicy: "network-only",
                    variables: {
                        orderNumber,
                        divisionUuid: this.activeAccount.externalUuid,
                        customerName: this.activeAccount.slug,
                    },
                });
                const widget = data.statisticsWidget || { ...EMPTY_WIDGET };
                widget.isLoading = false;
                if (widget.value === null) widget.placeholder = this.placeholderWidgets.pop();
                this.statistics.splice(orderNumber - 1, 1, widget);
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    },
    created() {
        this.getQuickLinksWidget();
        this.getUpdatesWidget();
        this.getStatisticsWidget(1);
        this.getStatisticsWidget(2);
        this.getStatisticsWidget(3);
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "@/style_variables/style_variables.scss";

.home_page {
    @include viewportHeight(100, 54px);
    @media (max-width: 1000px) {
        @include viewportHeight(100, 48px);
    }
    overflow-y: auto;
    padding: 40px 0 40px 0;
}
.widget_grid {
    display: grid;
    grid-template-columns: repeat(6, 117px);
    grid-template-rows: max-content max-content repeat(3, 117px);
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 40px 40px;
    grid-auto-flow: row;
    grid-template-areas:
        "banner banner banner banner banner banner"
        "banner banner banner banner banner banner"
        "small_widget_0 small_widget_0 small_widget_1 small_widget_1 small_widget_2 small_widget_2"
        "news_widget news_widget news_widget quick_links_widget quick_links_widget date_widget"
        "news_widget news_widget news_widget quick_links_widget quick_links_widget contact_link_widget";
    justify-content: center;
    @media (max-width: 1200px) {
        grid-template-areas:
            "banner banner banner banner"
            "banner banner banner banner"
            "small_widget_0 small_widget_0 small_widget_1 small_widget_1"
            "small_widget_2 small_widget_2 news_widget news_widget"
            "quick_links_widget quick_links_widget news_widget news_widget"
            "quick_links_widget quick_links_widget date_widget contact_link_widget";
        grid-template-columns: repeat(4, 117px);
        grid-template-rows: max-content max-content repeat(4, 117px);
    }
    @include media($isPhone...) {
        grid-template-areas:
            "banner banner"
            "banner banner"
            "banner banner"
            "small_widget_0 small_widget_0"
            "small_widget_1 small_widget_1"
            "small_widget_2 small_widget_2"
            "news_widget news_widget"
            "news_widget news_widget"
            "quick_links_widget quick_links_widget"
            "quick_links_widget quick_links_widget"
            "date_widget contact_link_widget";
        grid-template-columns: repeat(2, 117px);
        grid-template-rows: max-content max-content repeat(9, 117px);
    }
    > div {
        border: 1px solid $grey_alabaster;
        border-radius: 8px;
        background-color: $white;
    }
}

.small_widget_0 {
    grid-area: small_widget_0;
}

.small_widget_1 {
    grid-area: small_widget_1;
}

.small_widget_2 {
    grid-area: small_widget_2;
}

.news_widget {
    grid-area: news_widget;
}

.quick_links_widget {
    grid-area: quick_links_widget;
}

.date_widget {
    grid-area: date_widget;
}

.contact_link_widget {
    grid-area: contact_link_widget;
}

.banner {
    grid-area: banner;
}

.homepage__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.statistics_widget {
    height: 100%;
}
</style>
